export default {
  admin: [
    {
      title: '用戶',
      children: [
        {
          title: '用戶管理',
          to: { name: 'member' },
        },
        {
          title: '所有管理員',
          to: { name: 'admin' },
        },
        {
          title: '管理員權限',
          to: { name: 'access' },
        },
      ],
    },
    {
      title: '課程管理',
      children: [
        {
          title: '課程分類',
          to: { name: 'courseCategory' },
        },
        {
          title: '線上課程',
          to: { name: 'onlineCourse' },
        },
        {
          title: '實體課程',
          to: { name: 'faceCourse' },
        },
        {
          title: '課程時間表',
          to: { name: 'studentTimetable' },
        },
      ],
    },
    {
      title: '訂單',
      children: [
        {
          title: '課程訂單',
          to: { name: 'order' },
        },
      ],
    },
    {
      title: '宣傳管理',
      children: [
        {
          title: '優惠券',
          to: { name: 'coupon' },
        },
/*         {
          title: '推送通知',
          to: { name: 'promotionNotification' },
        }, */
      ],
    },
    {
      title: '設定',
      children: [
        {
          title: '宣傳橫額',
          to: { name: 'banner' },
        },
        {
          title: '其他設定',
          to: { name: 'setting' },
        },
        {
          title: '常見問題',
          to: { name: 'FAQ' },
        },
        {
          title: '客戶評價',
          to: { name: 'Testimonials' },
        },
      ],
    },
    {
      title: '日誌',
      children: [
        {
          title: '日誌',
          to: { name: 'blog' },
        },
        {
          title: '日誌分類',
          to: { name: 'blogCategory' },
        },
      ],
    },
    {
      title: '其他',
      children: [
        {
          title: '查詢',
          to: { name: 'contact' },
        },
        {
          title: '訂閱',
          to: { name: 'Subscription' },
        },
        {
          title: '報表',
          to: { name: 'report' },
        },
      ],
    },
  ],
}
