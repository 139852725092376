const state = {
  showLoadingPage: false,
  uploadProgress: 0,
  showUploadAlert: false,
  alertMessage: {
    show: false,
    message: '',
    type: 'success',
    refresh: false,
    redirect: '',
  },
  showOverlay: false,
  isLoading: false,
}

const getters = {
  showLoadingPage: state => state.showLoadingPage,
  uploadProgress: state => state.uploadProgress,
  showUploadAlert: state => state.showUploadAlert,
  alertMessage: state => state.alertMessage,
  showOverlay: state => state.showOverlay,
  isLoading: state => state.isLoading,
}

const actions = {
  toggleLoadingPage({ commit }, data = false) {
    commit('setLoadingPage', data)
  },
  controlUploadAlert({ commit }, data = null) {
    if (data === null) {
      commit('toggleUploadAlert', { show: false, progress: 0 })
    } else {
      commit('toggleUploadAlert', data)
    }
  },
  toggleAlertMessage({ commit }, data = null) {
    if (data === null) {
      commit('toggleAlert', {
        show: false,
        message: '',
        type: 'success',
        refresh: false,
        redirect: '',
      })
    } else {
      commit('toggleAlert', data)
    }
  },
  toggleOverlay({ commit }, data = false) {
    commit('setOverlay', data)
  },
  setLoading({ commit }, data = false) {
    commit('setLoading', data)
  },
}

const mutations = {
  setLoadingPage: function(state, bool) {
    state.showLoadingPage = bool
  },
  toggleUploadAlert: function(state, data) {
    state.showUploadAlert = data.show
    state.uploadProgress = data.progress
  },
  toggleAlert: function(state, data) {
    state.alertMessage = data
  },
  setOverlay: function(state, bool) {
    state.showOverlay = bool
  },
  setLoading: function(state, bool) {
    state.isLoading = bool
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
