export default [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      title: '登入',
      skipAuth: true,
    },
  },
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/Member.vue'),
    meta: {
      layout: 'content',
      title: '所有用戶',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/member/create',
    name: 'memberCreate',
    component: () => import('@/views/MemberDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增用戶',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/member/detail/:id',
    name: 'memberDetail',
    component: () => import('@/views/MemberDetail.vue'),
    meta: {
      layout: 'content',
      title: '用戶資料',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/Admin.vue'),
    meta: {
      layout: 'content',
      title: '所有管理員',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/admin/create',
    name: 'adminCreate',
    component: () => import('@/views/AdminDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增管理員',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/admin/detail/:id',
    name: 'adminDetail',
    component: () => import('@/views/AdminDetail.vue'),
    meta: {
      layout: 'content',
      title: '管理員資料',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('@/views/Access.vue'),
    meta: {
      layout: 'content',
      title: '權限設定',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/course-category',
    name: 'courseCategory',
    component: () => import('@/views/CourseCategory.vue'),
    meta: {
      layout: 'content',
      title: '課程分類',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/course-category/create',
    name: 'courseCategoryCreate',
    component: () => import('@/views/CourseCategoryDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增課程分類',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/course-category/detail/:id',
    name: 'courseCategoryDetail',
    component: () => import('@/views/CourseCategoryDetail.vue'),
    meta: {
      layout: 'content',
      title: '課程分類資料',
      skipAuth: false,
      requireAuth: true,
    },
  },

  {
    path: '/face-course',
    name: 'faceCourse',
    component: () => import('@/views/FaceCourse.vue'),
    meta: {
      layout: 'content',
      title: '實體課程',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/face-course/create',
    name: 'faceCourseCreate',
    component: () => import('@/views/FaceCourseDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增實體課程',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/face-course/detail/:id',
    name: 'faceCourseDetail',
    component: () => import('@/views/FaceCourseDetail.vue'),
    meta: {
      layout: 'content',
      title: '實體課程資料',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/face-course/module',
    redirect: 'faceCourse',
  },
  {
    path: '/face-course/module/:course_id/create',
    name: 'faceCourseModuleCreate',
    component: () => import('@/views/FaceCourseModule.vue'),
    meta: {
      layout: 'content',
      title: '新增實體課程單元',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/face-course/module/:course_id/detail/:lesson_id',
    name: 'faceCourseModuleDetail',
    component: () => import('@/views/FaceCourseModule.vue'),
    meta: {
      layout: 'content',
      title: '實體課程單元',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/online-course',
    name: 'onlineCourse',
    component: () => import('@/views/OnlineCourse.vue'),
    meta: {
      layout: 'content',
      title: '線上課程',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/online-course/create',
    name: 'onlineCourseCreate',
    component: () => import('@/views/OnlineCourseDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增線上課程',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/online-course/detail/:id',
    name: 'onlineCourseDetail',
    component: () => import('@/views/OnlineCourseDetail.vue'),
    meta: {
      layout: 'content',
      title: '線上課程資料',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/online-course/module',
    redirect: 'onlineCourse',
  },
  {
    path: '/online-course/module/:course_id/create',
    name: 'onlineCourseModuleCreate',
    component: () => import('@/views/OnlineCourseModule.vue'),
    meta: {
      layout: 'content',
      title: '新增線上課程單元',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/online-course/module/:course_id/detail/:video_id',
    name: 'onlineCourseModuleDetail',
    component: () => import('@/views/OnlineCourseModule.vue'),
    meta: {
      layout: 'content',
      title: '線上課程單元',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/timetable/detail/:id',
    name: 'studentTimetableDetail',
    component: () => import('@/views/TimetableDetail.vue'),
    meta: {
      layout: 'content',
      title: '報名學生',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/timetable',
    name: 'studentTimetable',
    component: () => import('@/views/Timetable.vue'),
    meta: {
      layout: 'content',
      title: '課程時間表',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order.vue'),
    meta: {
      layout: 'content',
      title: '課程訂單',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/order/create',
    name: 'orderCreate',
    component: () => import('@/views/OrderCreate.vue'),
    meta: {
      layout: 'content',
      title: '新增課程訂單',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/order/detail/:id',
    name: 'orderDetail',
    component: () => import('@/views/OrderDetail.vue'),
    meta: {
      layout: 'content',
      title: '課程訂單詳細',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/views/Coupon.vue'),
    meta: {
      layout: 'content',
      title: '所有優惠券',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/coupon/create',
    name: 'couponCreate',
    component: () => import('@/views/CouponDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增優惠券',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/coupon/detail/:id',
    name: 'couponDetail',
    component: () => import('@/views/CouponDetail.vue'),
    meta: {
      layout: 'content',
      title: '優惠券詳細',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/banner',
    name: 'banner',
    component: () => import('@/views/Banner.vue'),
    meta: {
      layout: 'content',
      title: '宣傳橫額',
      skipAuth: false,
      requireAuth: true,
    },
  },
  /* {
    path: '/notifiction',
    name: 'promotionNotification',
    component: () => import('@/views/PromotionNoti.vue'),
    meta: {
      layout: 'content',
      title: '推送通知',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/notifiction/create',
    name: 'notificationCreate',
    component: () => import('@/views/PromotionNotiDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增推送通知',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/notifiction/detail/:id',
    name: 'notificationDetail',
    component: () => import('@/views/PromotionNotiDetail.vue'),
    meta: {
      layout: 'content',
      title: '推送通知詳細',
      skipAuth: false,
      requireAuth: true,
    },
  }, */
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/Setting.vue'),
    meta: {
      layout: 'content',
      title: '其他設定',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/setting/create',
    name: 'settingCreate',
    component: () => import('@/views/SettingDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增其他設定',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/setting/detail/:id',
    name: 'settingDetail',
    component: () => import('@/views/SettingDetail.vue'),
    meta: {
      layout: 'content',
      title: '其他設定詳細',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue'),
    meta: {
      layout: 'content',
      title: '查詢',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/Report.vue'),
    meta: {
      layout: 'content',
      title: '報表',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: () => import('@/views/Subscription.vue'),
    meta: {
      layout: 'content',
      title: '訂閱',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/Blog.vue'),
    meta: {
      layout: 'content',
      title: '日誌',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/blog/create',
    name: 'blogCreate',
    component: () => import('@/views/BlogDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增日誌',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/blog/detail/:id',
    name: 'blogDetail',
    component: () => import('@/views/BlogDetail.vue'),
    meta: {
      layout: 'content',
      title: '日誌詳細',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/blog-category',
    name: 'blogCategory',
    component: () => import('@/views/BlogCategory.vue'),
    meta: {
      layout: 'content',
      title: '日誌分類',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/blog-category/create',
    name: 'blogCategoryCreate',
    component: () => import('@/views/BlogCategoryDetail.vue'),
    meta: {
      layout: 'content',
      title: '新增日誌分類',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/blog-category/detail/:id',
    name: 'blogCategoryDetail',
    component: () => import('@/views/BlogCategoryDetail.vue'),
    meta: {
      layout: 'content',
      title: '日誌分類詳細',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/views/FAQ.vue'),
    meta: {
      layout: 'content',
      title: '常見問題',
      skipAuth: false,
      requireAuth: true,
    },
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('@/views/Testimonials.vue'),
    meta: {
      layout: 'content',
      title: '客戶評價',
      skipAuth: false,
      requireAuth: true,
    },
  },
]
