import Vue from 'vue'
import Vuex from 'vuex'

// eslint-disable-next-line import/no-named-as-default
import VuexPersistence from 'vuex-persist'

import Common from './modules/Common'
import RemoveHelper from './modules/RemoveHelper'
import auth from './modules/auth'
import dialog from './modules/dialog'
import request from './modules/request'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    Common,
    RemoveHelper,
    auth,
    dialog,
    request,
  },
  plugins: [
    new VuexPersistence({
      key: 'vuex',
      storage: window.localStorage,
      modules: ['RemoveHelper'],
    }).plugin,
  ],
})
