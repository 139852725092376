const SET_DIALOG = 'SET_DIALOG'
const SHOW_DIALOG = 'SHOW_DIALOG'

export default {
  state: {
    message: '',
    isError: false,
    showDialog: false,
    returnLink: null,
    refresh: false,
  },
  actions: {
    setDialogMessage: ({ commit }, { message, isError, returnLink, refresh = false }) => {
      commit(SET_DIALOG, { message, isError, returnLink, refresh })
    },

    setShowDialog: ({ commit }, value) => {
      commit(SHOW_DIALOG, value)
    },
  },
  mutations: {
    [SET_DIALOG]: (state, { message, isError, returnLink, refresh }) => {
      state.message = message
      state.isError = isError
      state.returnLink = returnLink
      state.refresh = refresh
    },
    [SHOW_DIALOG]: (state, value) => {
      state.showDialog = value
    },
  },
}
