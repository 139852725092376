const state = {
  removedImageList: [],
  removedVimeoVideoIDList: [],
}

const getters = {
  removedImageList: state => state.removedImageList,
  removedVimeoVideoIDList: state => state.removedVimeoVideoIDList,
}

const actions = {
  insertRemovedImage({ commit }, data) {
    commit('insertRemovedImage', data)
  },
  insertRemovedImageList({ commit }, data) {
    commit('insertRemovedImageList', data)
  },
  resetRemovedImage({ commit }, data = []) {
    commit('resetRemovedImage', data)
  },
  insertRemovedVimeoVideoID({ commit }, data) {
    commit('insertRemovedVimeoVideoID', data)
  },
  insertRemovedVimeoVideoIDList({ commit }, data) {
    commit('insertRemovedVimeoVideoIDList', data)
  },
  resetRemovedVimeoVideoID({ commit }, data = []) {
    commit('resetRemovedVimeoVideoID', data)
  },
}

const mutations = {
  insertRemovedImage: function(state, data) {
    state.removedImageList.push(data)
  },
  insertRemovedImageList: function(state, data) {
    state.removedImageList = state.removedImageList.concat(data)
  },
  resetRemovedImage: function(state, data = []) {
    state.removedImageList = data
  },
  insertRemovedVimeoVideoID: function(state, data) {
    state.removedVimeoVideoIDList.push(data)
  },
  insertRemovedVimeoVideoIDList: function(state, data) {
    state.removedVimeoVideoIDList = state.removedVimeoVideoIDList.concat(data)
  },
  resetRemovedVimeoVideoID: function(state, data = []) {
    state.removedVimeoVideoIDList = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
