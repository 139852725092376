<template>
  <v-list-item
    class="vertical-nav-menu-link"
    v-bind="$attrs"
    active-class="primary white--text"
  >
    <v-list-item-title>{{ title }}</v-list-item-title>
  </v-list-item>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>

.vertical-nav-menu-link {
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    @include elevationTransition();
  }
}
</style>
