const SET_USER = 'SET_USER';

export default {
   state: {
      userType: "admin",
      userName: "Innpression Limited",
   },
   mutations: {
      [SET_USER]: (state, payload) => {
         state.userType = payload.userType;
         state.userName = payload.userName;
      },
   },
   actions: {
      setUser: ({ commit }, payload) => {
         commit(SET_USER, payload);
      },
   },
}
