<template>
  <v-dialog v-model="showDialog" width="500" ref="success-dialog" persistent>
    <v-card color="text-center">
      <v-card-text class="d-flex flex-column justify-center align-center">
        <v-avatar class="mt-10" icon size="50" v-if="dialogIsError">
          <v-icon size="3rem" color="error">{{ icons.mdiAlertCircleOutline }}</v-icon>
        </v-avatar>
      </v-card-text>
      <v-card-text>
        <h6 class="text-xl mt-4 font-weight-medium">{{ dialogMessage }}</h6></v-card-text
      >
      <v-card-text>
        <v-btn depressed color="primary accent-4" class="mt-4 white--text px-8" @click.prevent="btnAction">OK</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js'

export default {
  name: 'Dialog',
  setup() {
    return {
      icons: {
        mdiAlertCircleOutline,
        mdiCheckCircleOutline,
      },
    }
  },
  computed: {
    ...mapState({
      dialogMessage: state => state.dialog.message,
      dialogIsError: state => state.dialog.isError,
      returnLink: state => state.dialog.returnLink,
      refresh: state => state.dialog.refresh,
    }),
    showDialog() {
      return this.$store.state.dialog.showDialog
    },
  },
  methods: {
    ...mapActions(['setShowDialog'], 'dialog'),
    async btnAction() {
      if (this.returnLink) {
        if (this.returnLink.name === 'login') {
          const userData = await this.getCurrentUserData()
          await this.clearVerifyToken(userData.id)
        }
        this.$router.replace(this.returnLink)
      } else if (this.refresh) {
         this.$router.go()
      }

      this.setShowDialog(false)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>