import Vue from 'vue'
import VueRouter from 'vue-router'
import mainRoute from './main'
import Cookies from 'js-cookie'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  ...mainRoute,
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('toggleLoadingPage', false)

  if (to.meta.requireAuth) {
    // login cookie check
    let loginCookie = Cookies.get('SPARK_EDU_CMS_LID')

    if (loginCookie !== undefined && loginCookie !== '') {
      /* if (to.meta.cannotAccess !== undefined && to.meta.cannotAccess !== null && to.meta
        .cannotAccess.length > 0) {

        const params = new URLSearchParams()
        params.set('cms_get_admin_by_token', JSON.stringify({
          verify_token: loginCookie.verify_token
        }))

        const response = await axios.post('/hr_backend/develop/request.php', params)

        if (response.data.result === 'success') {
          const data = response.data.data

          if (to.meta.cannotAccess.includes(data.admin_type)) {
            next(defaultPage[data.admin_type])
          } else {
            next()
          }
        } else {
          Cookies.remove('SPARK_EDU_CMS_LID')
          next('/login')
        }
      } else {
        next()
      } */
      next()
    } else if (to.path !== '/login') {
      Cookies.remove('SPARK_EDU_CMS_LID')
      next('/login')
    } else {
      next()
    }
  } else if (to.meta.skipAuth) {
    const loginCookie = Cookies.get('SPARK_EDU_CMS_LID')
    if (loginCookie !== undefined && loginCookie !== '') {
      next('/member')
    } else {
      next()
    }
  } else {
    next()
  }
})

const DEFAULT_TITLE = 'Spark Education'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    store.dispatch('resetRemovedImage', [])
    store.dispatch('resetRemovedVimeoVideoID', [])
    document.title = (`${to.meta.title} - ` || '') + DEFAULT_TITLE
  })
})

export default router
